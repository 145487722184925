<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pt-4 pl-3 m-0 bg-current">
                    <h3 class="card-title text-white"><i class="fas fa-plus mr-2"></i> Create Syllabus Unit</h3>
                </div>
                <div class="card-body">
                    <form @submit.prevent="postPoint()" class="row">
                        <div class="form-group col-lg-6">
                            <label for="">Name</label>
                            <input type="text" v-model="point.content" class="form-control" placeholder="Insert Unit Name..." required>
                        </div>
                        <div class="form-group col-lg-6">
                            <label for="">Term</label>
                            <select v-model="point.cycle" class="form-control" required>
                                <option value="" selected disabled>-- Choose Cycle --</option>
                                <option value="1">Term 1</option>
                                <option value="2">Term 2</option>
                                <option value="3">Term 3</option>
                            </select>
                        </div>
                        <div class="form-group col-lg-12">
                            <label for="">Description</label>
                            <textarea v-model="point.description" rows="3" class="form-control" placeholder="Insert Unit Description..." required></textarea>
                        </div>
                        <div class="form-group col-12 text-right">
                            <label for="">&nbsp;</label>
                            <button type="submit" class="btn btn-current">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            idSyllabus: this.$route.params.idSyllabus,
            point: {
                content: '',
                cycle: '',
                description: '',
            }
        }
    },
    methods: {
        async postPoint(){
            var data ={
                slug: this.paramsId,
                syllabus_id: this.idSyllabus,
                content: this.point.content,
                cycle: this.point.cycle,
                description: this.point.description
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/syllabus/point`, data,  {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Syllabus Unit',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'SyllabusCoursesAdmin', params: { idCourse: this.paramsId } })
            }).catch(() => {
                this.$swal({
                    toast: true,
                    title: 'Syllabus Unit',
                    text: 'Failed Create New Syllabus Unit!',
                    icon: 'error',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            })
        },
    }
}
</script>